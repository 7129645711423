<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Emergency Contact</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      size="small"
      variant="outlined"
      color="primary"
      class="flex items-center gap-2 mt-2 md:mt-0 border-primary rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Contact</span>
    </easiButton>
  </div>
  <hr class="text-dark-100" />
  <div
    v-if="contacts && contacts.length"
    class="p-6 md:p-8 flex flex-col gap-6 overflow-y-auto"
    style="height: 500px"
  >
    <div
      v-for="(item, i) in contacts"
      :key="i"
      class="flex flex-col gap-4 border-b border-dark-100 last:border-0 pb-6"
    >
      <div
        class="text-2xl md:text-3xl flex items-center justify-between font-medium text-dark-500 my-2"
      >
        Contact {{ i + 1 }}

        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateId(item)"
          size="small"
          color="primary"
          variant="outlined"
          class=""
        >
          <span class="flex items-center mr-2 text-base gap-3">
            <img class="w-4" src="@/assets/icons/edit-primary.svg" alt="" />

            Edit
          </span>
        </easiButton>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Name</span>
        <span class="text-base text-dark-800 font-medium"
          >{{ item.fullName }}
        </span>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Email Address</span>
        <span class="text-base text-dark-800 font-medium">
          {{ item.email }}
        </span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Phone Number(s)</span>
        <span class="text-base text-dark-800 font-medium">{{
          item.phone.join(",")
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Home Address</span>
        <span class="text-base text-dark-800 font-medium">{{
          item.address
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Relationship</span>
        <span class="text-base text-dark-800 font-medium">{{
          item.relationship
        }}</span>
      </div>
    </div>
  </div>
  <div v-else class="my-8">
    <easiEmptyState text="No Emergency Contacts added" />
  </div>
  <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
    <EmergencyAdd type="add" />
  </easiModal>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import EmergencyAdd from "@/components/Employee/Edit/Emergency/Emergency.vue";

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["contact"]);

const store = useDataStore();
const route = useRoute();
const showAdd = ref(false);
const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const contacts = computed(() => employee && employee.value.emergency);
function updateId(obj) {
  emit("contact", obj);
}
console.log(employee.value);

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
