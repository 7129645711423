<template>
  <div
    v-if="edit"
    :class="type === 'add' ? 'pb-10' : 'py-10'"
    class="md:full w-full mx-auto text-left"
  >
    <div class="mb-0">
      <h2
        v-if="type === 'add'"
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Add Emergency Contact
      </h2>
      <h2
        v-else
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Emergency Contact
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="UpdateEmergencyContact(true)"
      autocomplete="on"
      class="flex flex-col gap-y-4 px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <!-- <label for="fullName" class="text-dark-800 text-sm font-medium"
        >Full Name</label
      > -->
      <easiTextInput
        placeholder="Full Name"
        type="text"
        name="fullName"
        class="mt-1 mb-5"
        required
        v-model="args.fullName"
      ></easiTextInput>

      <!-- <label for="email" class="text-dark-800 text-sm font-medium"
        >Email Address
        <span class="text-xs text-gray tracking-wide">
          (Recommended)</span
        ></label
      > -->
      <easiTextInput
        placeholder="Email Address (Recommended)"
        type="email"
        name="email"
        class="mt-1 mb-5"
        v-model="args.email"
        :error="errorRules.email"
        autocom
      ></easiTextInput>

      <!-- <label for="phoneNumber" class="text-dark-800 text-sm font-medium"
        >Phone Number
        <span class="text-xs text-gray tracking-wide">
          (Recommended)</span
        ></label
      > -->
      <easiTextInput
        label="Phone Number (Recommended)"
        type="tel"
        name="phoneNumber"
        class="mt-1 mb-5"
        v-model="otherNumbers"
        :error="errorRules.phone"
      ></easiTextInput>

      <!-- <label for="address" class="text-dark-800 text-sm font-medium"
        >Home Address</label
      > -->

      <easiTextArea
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mb-1"
        placeholder="Home Address"
        name="address"
        id="address"
        cols="30"
        rows="5"
        v-model="args.address"
      ></easiTextArea>

      <!-- <label for="relationship" class="text-dark-800 text-sm font-medium"
        >Relationship</label
      > -->
      <easiTextInput
        placeholder="Relationship"
        type="text"
        name="relationship"
        class="mt-1 mb-5"
        required
        v-model="args.relationship"
      ></easiTextInput>
      <!-- 
      <label class="text-dark-800 text-sm font-medium">Country</label>
      <easiSelectInput2
        required
        class="mt-1 mb-5"
        @update="args.country = $event"
        :value="args.country"
        :options="countryData"
      /> -->

      <div
        v-if="type === 'edit'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          @click="edit = false"
          type="button"
          color="primary"
          variant="outlined"
          class="bg-white w-80 md:w-72"
        >
          Cancel
        </easiButton>
        <easiButton
          :loading="loading.edit"
          type="submit"
          color="primary"
          class="w-80 md:w-72"
          :class="loading.edit ? 'w-80 md:w-40' : 'w-fit'"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>
      <!-- ////// Add Buttons ///// -->
      <div
        v-if="type === 'add'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          type="button"
          @click="save"
          color="primary"
          variant="outlined"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Save And add more </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span
          >Contact
          {{ type === "edit" ? "updated" : "added" }} successfully</span
        >
      </template>
    </easiSuccess>
  </div>
  <ViewEmergency @contact="update($event)" v-else />
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";
import ViewEmergency from "@/components/Employee/View/Emergency/Emergency";

import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  nextTick,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import Countries from "@/utils/countries";

import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const { resetObj, validatePhone, validateEmail } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

const route = useRoute();
const props = defineProps({
  type: {
    type: String,
    default: "edit",
  },
});
const employee = computed(() => store.getEmployeeById(route.params.id));

const loading = ref({
  add: false,
  edit: false,
});
const errorRules = ref({
  phone: "",
  email: "",
});

const edit = ref(false);
const countryData = computed(() => {
  let stateArray = [];
  stateArray = Countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });
  console.log(stateArray);
  return stateArray;
});
let updateSuccess = ref(false);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

let args = reactive({
  _id: null,
  fullName: "",
  relationship: "",
  address: "",
  phone: [],
  email: "",
});
const argsArray = ref([]);
const otherNumbers = ref([]);

const employeeId = route.params.id;
const profileArgsFromStore = computed(() => store.profileData);

function update(event) {
  edit.value = true;
  const arr = Object.keys(event);
  const assign = arr.map((key) => {
    args[key] = event[key];
    return event;
  });
  delete args["__typename"];
  if (args.phone.length) {
    otherNumbers.value = args.phone.join(",");
  }
}
function addMore(arg) {
  if (otherNumbers.value.includes(",") && otherNumbers.value.length) {
    args.phone = otherNumbers.value.split(",");
  } else {
    let phone = [];
    if (otherNumbers.value && otherNumbers.value.length) {
      phone.push(otherNumbers.value);
      args.phone = phone;
    }
  }
  console.log(otherNumbers.value, "LENGth", args.phone);
  if (otherNumbers.value.length > 0) {
    for (let num of args.phone) {
      if (!validatePhone(num)) {
        errorRules.value.phone = "Please ensure all phone numbers are valid";
        loading.value[props.type] = false;
        throw new Error("Invalid");
      }
    }
  }

  if (args.email.length > 0) {
    if (!validateEmail(args.email)) {
      errorRules.value.email = "Please enter a valid email address";
      loading.value[props.type] = false;
      throw new Error("Invalid");
    }
  }

  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });

  return argsArray.value;
}

async function save() {
  addMore(args);
  await UpdateEmergencyContact(false);

  otherNumbers.value = "";

  console.log(args, "CHECK");
  console.log(profileArgsFromStore.value, "NO REPEAT");
  // window.location.reload();
}

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}

// function assignValues() {
//   const keys = Object.keys(employees[0].profile);
//   const argkeys = Object.keys(args);
//   // newObj = keys.map((k) => {
//   //   argkeys.map((ak) => {
//   //     if (k === ak) {
//   //       args[ak] = employees[k];
//   //     }
//   //   });
//   // });
//   for (let k of keys) {
//     for (let ak of argkeys) {
//       if (k === ak) {
//         args[ak] = employees[0].profile[k];
//       }
//     }
//   }
//   console.log(args);
// }

async function UpdateEmergencyContact(final) {
  if (final) {
    loading.value[props.type] = true;
    addMore(args);
  } else {
    loading.value.save = true;
  }

  try {
    let res = await mutate({
      endpoint: "UpdateEmergencyContact",
      data: {
        employeeId,
        input: profileArgsFromStore.value,
      },
      service: "EMP",
    });

    console.log(res[res.length - 1].fullName);
    if (res[res.length - 1].fullName) {
      await queryEmployees();

      loading.value[props.type] = false;
      loading.value.save = false;

      if (final) {
        updateSuccess.value = true;
        setTimeout(() => {
          updateSuccess.value = false;
          // edit.value = false;
          window.location.reload();
        }, 1000);
      } else {
        toast.success("Details saved!");
      }
      // toast.success("Emergency contact Updated Successfuly");
      // args = resetObj(args, "args");
      Object.assign(args, resetObj(args, "args"));
      store.$patch({
        profileArr: [],
      });
    } else {
      loading.value[props.type] = false;
      loading.value.save = false;
    }
  } catch (e) {
    loading.value[props.type] = false;
    loading.value.save = false;
    console.log(e);
    args = resetObj(args, "args");
    store.$patch({
      profileArr: [],
    });
  } finally {
    loading.value[props.type] = false;
    loading.value.save = false;
  }
}

queryEmployees();
// assignValues();

const hide = ref(true);
let message = ref("");
let fileName = ref("");
let fileSize = ref("");

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/gif", "image/jpeg"];
  if (!allowedTypes.includes(file.type)) {
    message.value = "File type is wrong!!";
    throw new Error(message.value);
  }
  if (file.size > 10000000) {
    message.value = "Too large, max size allowed is 10MB";
    throw new Error(message.value);
  }
  const { name, size } = file;
  fileName.value = name;

  fileSize.value = formatBytes(size);
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    console.log(e.target.result, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}
onMounted(async () => {
  props.type === "add" ? (edit.value = true) : (edit.value = false);
  await queryEmployees();
  store.$patch({
    profileArr: [],
  });
});

onUnmounted(() => {
  store.$patch({
    profileArr: [],
  });
});
</script>

<style></style>
